import { t } from "i18next"

function App() {
  return (
    <>
      <h2>1.{t("Click to connect wallet")}</h2>
      <p>{t("Issuing coins in batches")}<a href=" https://ton.ggg.dog/send"> https://ton.ggg.dog/send</a></p>
      <div className="images">
        <img src="/images/connect.webp" alt="tokenCreation" />
      </div>
      <h2>2.{t("Fill in the information")}</h2>
      <h3>2.1 {t("Choose to send Ton main currency or other tokens")}</h3>
      <h3>2.2 {t("To choose to send other tokens, you need to enter the token address")}</h3>
      <h3>
        2.3 {t("Fill in the payment address and quantity (each line should include the address and quantity, separated by commas)")}
      </h3>
      <p>{t("Eg")}: 0QCLBoC--JdBVhFdhhH9_gjnlEi3xzmCAXKyLl1iOvZ5IeIG,10</p>
      <div className="images">
        <img src="/images/batch.webp" alt="tokenCreation" />
      </div>
      <h2>3.{t("Click to send")}</h2>
      <p>{t("The wallet confirmation box pops up, click Confirm to complete the batch sending.")}</p>
    </>
  )
}
export default App