import { Box, styled, Typography } from "@mui/material";
// import styled from 'styled-components'
export const SenderPage = styled(Box)(({ theme }) => ({
  background: "#15191D",
  lineHeight: "60px",
  display: "flex",
  borderRadius: 6,
  color: "#FFF",
  ".tab": {
    padding: "0 20px",
    cursor: "pointer"
  },
  ".tab.active": {
    background: " #192C47",
    borderRadius: "6px",
  },
}))
export const SenderContent = styled(Box)(({ theme }) => ({
  background: "#15191D",
  borderRadius: 6,
  marginTop: 10,
  padding: "40px 60px",
  ".content": {
    "textarea,input": {
      width: "100%",
      background: "#15191D",
      border: "1px solid #808080",
      lineHeight: "57px",
      paddingLeft: 30,
      outline: "none",
      borderRadius: 10,
      color: "#FFF",
    },
    input:{
      margin:"10px 0",
      borderRadius: 10,

    }

  },
  p: {
    textAlign:"start",
    color: "rgb(118, 137, 174)"
  },
  [theme.breakpoints.down("sm")]: {
  padding: "20px 15px",

  }
}))