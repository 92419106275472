import { useState } from 'react'
import { useTonAddress, useTonConnectUI, SendTransactionRequest } from "@tonconnect/ui-react";
import { beginCell, toNano, Address } from 'ton'

import { toDecimalsBN } from "utils";
import { jettonDeployController } from "lib/deploy-controller";
import { zeroAddress, waitForSeqno } from "lib/utils";
import { getClient } from "lib/get-ton-client";
import { Segmented, Input, Button, message } from 'antd';
import styled from '@emotion/styled';
import { YAGONG } from 'consts'
import { transfer } from 'lib/jetton-minter';
import { SenderContent, SenderPage } from './style';

const { TextArea } = Input;

const Page = styled.div`
  min-height:70vh;
  font-size: 20px;
  margin-top: 200px;
  text-align: center;

  .ant-segmented-group {
    height: 46px;
    border: 0.5px solid rgba(114, 138, 150, 0.16);
  }
  .ant-segmented-item-label {
    height: 100%;
    display: flex;
    align-items: center;
  }

  .input {
    margin: 20px 0;
    input {
      height: 44px;
    }
  }
  .btn {
    margin-top: 40px;
    background: linear-gradient(106deg, #0CB8FF 0%, #1273FB 100%);
    border-radius:6px;
    line-height:44px;
    color:#FFF;
    cursor:pointer;
  }
  @media screen and (max-width:1200px){
    font-size: 16px;
    width:98%;
    margin:100px auto;

  }
`

function Sendertoken() {

  const address = useTonAddress();
  const [tonConnectUI, setOptions] = useTonConnectUI();
  const [tokenAddress, setTokenAddress] = useState('')
  const [values, setValues] = useState('')
  const [isTon, setIsTon] = useState(true)

  const tokenAddressChange = (e: any) => {
    setTokenAddress(e.target.value)
  }
  const valuesChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setValues(e.target.value)
  }

  const sendToken = async () => {

    try {
      const tokenArr: any[] = []
      const amuntArr: any[] = []
      const _arr = values.split(/[(\r\n)\r\n]+/)
      _arr.forEach((item, index) => {
        const arr = item.split(/[,，]+/)
        tokenArr.push(arr[0])
        amuntArr.push(arr[1])
      })

      console.log(tokenArr, amuntArr)

      const fromAddress = address
      const tc = await getClient();

      const waiter = await waitForSeqno(
        tc.openWalletFromAddress({
          source: Address.parse(fromAddress),
        }),
      );
      const messages: any[] = []

      if (!selected) {
        tokenArr.forEach((item, index) => {
          const obj = {
            address: item, // destination address
            amount: Number(amuntArr[index]) * 1000000000
          }
          messages.push(obj)
        })
      } else {
        const parsedJettonMaster = Address.parse(tokenAddress);
        const result = await jettonDeployController.getJettonDetails(
          parsedJettonMaster,
          address ? Address.parse(address) : zeroAddress(),
        );
        const decimals = result.minter.metadata.decimals || "9"
        const ownerJettonWallet = result.jettonWallet?.jWalletAddress.toFriendly()

        tokenArr.forEach((item, index) => {
          const _amount = toDecimalsBN(amuntArr[index]!, decimals!)
          const obj = {
            address: ownerJettonWallet as string,
            amount: toNano(0.05).toString(),
            stateInit: undefined,
            payload: transfer(Address.parse(item), Address.parse(fromAddress), _amount)
              .toBoc()
              .toString("base64"),
          }
          messages.push(obj)
        })

      }

      messages.push(
        {
          address: YAGONG, // destination address
          amount: 0.5 * 1000000000
        }
      )
      const tx: SendTransactionRequest = {
        validUntil: Date.now() + 5 * 60 * 1000,
        messages: messages,
      };
      await tonConnectUI.sendTransaction(tx);
      await waiter();
    } catch (error: any) {
      message.error(error.toString())
    }
  }

  const [selected, setSelected] = useState(0)
  return (
    <Page>


      <SenderPage>
        <div className={`tab ${selected === 0 ? "active" : ""}`} onClick={() => setSelected(0)}>
          TON发送
        </div>
        <div className={`tab ${selected === 1 ? "active" : ""}`} onClick={() => setSelected(1)}>
          其他代币发送
        </div>
      </SenderPage>
      <SenderContent>
        {selected === 1 && (
          <>
            <div className='content'>
              <div style={{ textAlign: "start", color: '#fff' }}>输入代币地址</div>
              <input value={tokenAddress} onChange={tokenAddressChange} />
            </div>
          </>
        )}
        <p>收款地址和数量(每一行应包括地址和数量，逗号分隔)</p>

        <div className='content'>
          <textarea value={values} onChange={valuesChange}
          />
        </div>

        <div className='btn' onClick={sendToken}>
          发送
        </div>
      </SenderContent>
    </Page>
  )
}

export default Sendertoken