import React, { useState } from 'react';
import type { MenuProps } from 'antd';
import { Menu } from 'antd';
import { useNavigate } from 'react-router-dom';
import './style.css'
import { AppstoreOutlined, BarsOutlined, DollarOutlined, PartitionOutlined } from "@ant-design/icons"
import { useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
type MenuItem = Required<MenuProps>['items'][number];



function getItem(
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[],
    type?: 'group',
): MenuItem {
    return {
        key,
        icon,
        children,
        label,
        type,
    } as MenuItem;
}

interface LevelKeysProps {
    key?: string
    children?: LevelKeysProps[]
}

interface MenuMobileProps {
    isNav: () => void;
}

const Navigation: React.FC<MenuMobileProps> = ({ isNav }) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const items: MenuItem[] = [
        getItem(
            t("Create"),
            '/',
            <div className="h-logo2">
                <DollarOutlined alt="" width={20} height={20} />
            </div>,
        ),
        getItem(
            t("Management"),
            '/management',
            <div className="h-logo2">
                <AppstoreOutlined alt="" width={20} height={20} />
            </div>,
        ),
        getItem(
            t("Market value(development...)"),
            '3',
            <div className="h-logo2">
                <BarsOutlined alt="" width={20} height={20} />
            </div>,
            [
                getItem(t("Market value(development...)"), '/marketValue'),
            ],
        ),
        getItem(
            t("Batch Transfer"),
            '/send',
            <div className="h-logo2">
                <PartitionOutlined alt="" width={20} height={20} />
            </div>,
        ),
        getItem(
            t("Liquidity(development...)"),
            '5',
            <div className="h-logo2">
                <img src="/images/trade.png" alt="trade" width={20} height={20} />
            </div>,
            [
                getItem(t("Liquidity(development...)"), '/fluidity'),
            ],
        ),
        getItem(
            t("Help documentation"),
            '/docs',
            <div className="logo2">
                <img src="/images/boss.png" alt="trade" width={20} height={20} />
            </div>,
        ),
        getItem(
            "ton学习（开发中）",
            '/learn',
            <div className="logo2">
                <img src="/images/learn.webp" alt="trade" width={20} height={20} />
            </div>,
        ),
    ];


    const getLevelKeys = (items1: LevelKeysProps[]) => {
        const key: Record<string, number> = {};
        const func = (items2: LevelKeysProps[], level = 1) => {
            items2.forEach((item) => {
                if (item.key) {
                    key[item.key] = level;
                }
                if (item.children) {
                    return func(item.children, level + 1);
                }
            });
        };
        func(items1);
        return key;
    };
    const levelKeys = getLevelKeys(items as LevelKeysProps[]);

    const [stateOpenKeys, setStateOpenKeys] = useState<string[]>([]);

    const onOpenChange: MenuProps['onOpenChange'] = (openKeys) => {
        const currentOpenKey = openKeys.find((key) => stateOpenKeys.indexOf(key) === -1);
        if (currentOpenKey !== undefined) {
            const repeatIndex = openKeys
                .filter((key) => key !== currentOpenKey)
                .findIndex((key) => levelKeys[key] === levelKeys[currentOpenKey]);

            setStateOpenKeys(
                openKeys
                    .filter((_, index) => index !== repeatIndex)
                    .filter((key) => levelKeys[key] <= levelKeys[currentOpenKey]),
            );
        } else {
            setStateOpenKeys(openKeys);
        }
    };

    const menuClick: MenuProps['onClick'] = (e) => {
        isNav(); // Toggle navigation state
        navigate(e.key);
    };
    const matches1 = useMediaQuery("(max-width:600px)");

    return (
        <Menu
            mode="inline"
            theme="dark"
            openKeys={stateOpenKeys}
            onOpenChange={onOpenChange}
            items={items}
            style={{ background: "rgb(26, 26, 26)", height: '100vh', width: "70vw", position: "fixed", top: matches1 ? 50 : "80px", right: "0", zIndex: 1000 }}
            onClick={menuClick}
        />
    );
};

export default Navigation;
